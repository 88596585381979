import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { ContentfulApiService } from '../../services/contenful/contentful-api.service';

@Component({
    selector: "app-pricing",
    templateUrl: "./pricing.component.html",
    styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
    readonly enUS = "en-US";
    files = [];
    constructor(
        private contentfulService: ContentfulApiService,
        private http: HttpClient,
        private titleService: Title
    ) {
        this.titleService.setTitle("Fichiers de formation");
    }

    ngOnInit() {
        this.loadFiles();
    }

    loadFiles() {
        this.contentfulService.getAllAssets().then((response) => {
            this.files = response.items.map( (item: any) => {
                const url = item.fields.file[this.enUS].url;
                const data = {
                    name: item.fields.title[this.enUS] || "Sans nom",
                    description:
                        item.fields.description[this.enUS] ||
                        "Pas de description",
                    url: url
                };

                return data;
            });

            console.log(this.files);
        });
    }
}
