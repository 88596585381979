import { Injectable } from '@angular/core';
import { createClient, ClientAPI } from "contentful-management";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root",
})
export class ContentfulApiService {
    private client: ClientAPI;

    constructor() {
        this.client = createClient({
            space: environment.contentful.spaceId,
            accessToken: environment.contentful.accessToken,
        });
    }

    async getAllAssets(): Promise<any> {
      const space = await this.client.getSpace(environment.contentful.spaceId);
      const env = await space.getEnvironment(environment.contentful.environmentId);
      const assets = env.getPublishedAssets();
      console.log(assets);
      return assets ?? [];
    }
}
