<!-- Start Page Title Area -->
<section class="page-title-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h2>DESIGN ATELIER STUDENTS PRIZE</h2>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="img"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="img"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="img"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="img"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape8.svg" alt="shape"></div>
    <div class="shape9"><img src="assets/img/shape9.svg" alt="shape"></div>

</section>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<section class="pricing-area ptb-20">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let file of files">
                <div class="col-lg-4 col-md-6 offset-md-1">
                    <div class="pricingTable">
                        <div class="pricing-icon">
                            <i class="icofont-file-pdf"></i>
                        </div>
                        <div class="price-Value">
                            {{file.description}}
                        </div>
                        <div class="pricingHeader">
                            <h3 class="title">{{file.name}}</h3>
                        </div>
                        <a [href]="file.url" target="_blank" class="btn btn-primary" download>Telecharger</a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<!-- End Pricing Area -->