<!-- Start Footer Area -->
<footer class="footer-area ptb-100 pb-0 bg-image">
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo2.png" alt="logo2"></a>
                    <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project individual.</p>
                    <ul class="social-list">
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Company</h3>

                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Carrers</a></li>
                        <li><a routerLink="/">Awards</a></li>
                        <li><a routerLink="/">User Program</a></li>
                        <li><a routerLink="/">Locations</a></li>
                        <li><a routerLink="/">Login</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Products</h3>

                    <ul class="list">
                        <li><a routerLink="/">Integrations</a></li>
                        <li><a routerLink="/">API</a></li>
                        <li><a routerLink="/">Pricing</a></li>
                        <li><a routerLink="/">Documentation</a></li>
                        <li><a routerLink="/">Sign Up</a></li>
                        <li><a routerLink="/">Release Notes</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a routerLink="/">About</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Press</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

    <div class="copyright-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy;2024. All rights reserved</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Back Top top -->
<div class="back-to-top">Top</div>
<!-- End Back Top top -->